
import React from 'react';

import {connect} from 'react-redux';



import './veamyhealth.scss';






const Pveamyhealth = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id65a9c0dcb0184704f9089f87" >

      <div id="ikce" className="gjs-row ">
      
      <div id="ihzb" className="gjs-cell ">
      
      <img
        className=""
        id="ieu9"
        src="https://assetsprojects.s3.amazonaws.com/406racilqnu0zqk.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i09j" className="gjs-cell ">
      
        <a
          target=""
          className=""
          id="iv0qk"
          href="#"
        >
          
    <div id="idbvk" className="">
    <span>Lenguaje</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="ihg44"
          href={`undefined`}
        >
          
    <div id="i8zbk" className="">
    <span>Herramientas</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="idwu" className="">
      
      <div id="iniq1" className="">
      
      <b className="" id="i504">
        <span>Tecnología que transforma el cuidado de la salud</span>
      </b>
      
      <p className="" id="ixrtu">
        <span>Cambiando la forma en que se gestionan las enfermedades crónicas</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iz34g" className="">
      
          <iframe
            title="Video Youtube"
            id="iv0cj"
            className=""
            src="https://www.youtube.com/embed/kVfMv9fwjfI?"
            allowFullScreen="undefined"
          />
          
      </div>
      
      <div id="i44sq" className="">
      
      <p className="" id="im389">
        <span>Herramientas tecnológcas diseñadas por médicos</span>
      </p>
      
      <p className="" id="i8qz5">
        <span>Empoderando al personal de la salud</span>
      </p>
      
      </div>
      
      <div id="ifkp1" className="">
      
      <p className="" id="i0mrl">
        <span>Un problema </span>
      <br className="" id="" />
      <span>en crecimiento...</span>
      </p>
      
      <img
        className=""
        id="iq23g"
        src="https://assetsprojects.s3.amazonaws.com/406racilsa8avgv.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iikjr" className="">
      
      <img
        className=""
        id="iznqp"
        src="https://assetsprojects.s3.amazonaws.com/406racilsa83vjc.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i3ffh"
        src="https://assetsprojects.s3.amazonaws.com/406racilry3ukne.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i8gv4" className="">
      
      <p className="" id="iw20m">
        <span>Herramientas para médicos</span>
      </p>
      
      </div>
      
      <div id="i2fga" className="gjs-row ">
      
      <div id="ial8f" className="gjs-cell ">
      
      <img
        className=""
        id="i9gc6"
        src="https://assetsprojects.s3.amazonaws.com/406racilsa7jhtm.png"
        alt="undefined"
      />
      
        <a
          target="_blank"
          className=""
          id="iaz0y"
          href="https://ajusteinsulinoterapia-2f8c36193088.herokuapp.com/"
        >
          
    <div id="ihev4" className="">
    <span>Calculadora de Insulina</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="ij1lp" className="gjs-cell ">
      
      <img
        className=""
        id="ip02x"
        src="https://assetsprojects.s3.amazonaws.com/406racilsa7l0dp.png"
        alt="undefined"
      />
      
        <a
          target="_blank"
          className=""
          id="i2p5i"
          href="https://calculadoralevotiroxina-4f312df920cb.herokuapp.com/"
        >
          
    <div id="i7kp5" className="">
    <span>Caculadora de Levotiroxina</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="iqa5d" className="">
      
      <p className="" id="iiu5x">
        <span>NOSOTROS</span>
      </p>
      
      </div>
      
      <div id="i2til" className="">
      
      </div>
      
      <div id="i23q4" className="">
      
      </div>
      
      <div id="i3wdv" className="">
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pveamyhealth);
  