
import React from 'react';

import {connect} from 'react-redux';



import './mantenimiento.scss';






const Pmantenimiento = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id65ab3164b0184704f908b1ee" >

      <div id="i5zdr" className="">
      
      <div id="ip5yj" className="">
      
      <div id="invki" className="">
      
      <div id="i5tpi" className="">
      
      <p className="" id="i5al8">
        <span>¡LO SIENTO!</span>
      </p>
      
      <p className="" id="idyhz">
        
      <i className="" id="ipbfl">
        <span>Nuestro sitio web está en construcción</span>
      </i>
      
      </p>
      
      <p className="" id="iqygh">
        <span>Estamos preparando algo increíble y emocionante para ti. Sorpresa especial solo para nuestros suscriptores</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i454b" className="">
      
      <img
        className=""
        id="ibsby"
        src="https://assetsprojects.s3.amazonaws.com/406racilrlhjiy5.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pmantenimiento);
  