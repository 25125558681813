
import React from 'react';

import {connect} from 'react-redux';



import './ingles.scss';






const Pingles = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id65a9dd84b0184704f908ac47" >

      <div id="ikce" className="gjs-row ">
      
      <div id="ihzb" className="gjs-cell ">
      
      <img
        className=""
        id="ieu9"
        src="https://assetsprojects.s3.amazonaws.com/406racilqnu0zqk.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i09j" className="gjs-cell ">
      
      </div>
      
      </div>
      
      <div id="idwu" className="">
      
      <p className="" id="iu5m">
        
      </p>
      
      <b className="" id="i504">
        
      <br className="" id="" />
      <span>Tecnología que transforma el cuidado de la salud</span>
      </b>
      
      <p className="" id="ixrtu">
        <span>Cambiando la forma en que se gestionan las enfermedades crónicas</span>
      </p>
      
      </div>
      
      <div id="" className="gjs-row ">
      
      <div id="i7xux" className="gjs-cell ">
      
      <img
        className=""
        id="iwvhh"
        src="https://assetsprojects.s3.amazonaws.com/406racilqnsw3fx.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ib992" className="gjs-cell ">
      
          <iframe
            title="Video Youtube"
            id="ii1o4"
            className=""
            src="https://www.youtube.com/embed/kVfMv9fwjfI?"
            allowFullScreen="undefined"
          />
          
      </div>
      
      </div>
      
      <div id="i8iut" className="">
      
      <p className="" id="ipqrw">
        
      </p>
      
      <b className="" id="iowmr">
        
      <br className="" id="" />
      <span>Herramientas tecnológicas diseñadas por médicos</span>
      </b>
      
      <p className="" id="il8xn">
        <span>Empoderando al personal de la salud para brindar un trato personalizado a los pacientes</span>
      </p>
      
      </div>
      
      <div id="ic7o7" className="">
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pingles);
  